import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import { showToast } from '@/helpers/toast.helper';
import { ProductCard } from '@/store/modules/products/actions';
import { ProductsUpdateRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import MultiselectInput from '@/components/ui/inputs/multiselect-input/multiselect-input.vue';

@Component({
  name: 'UpdateProductModal',
  components: {
    ValidationObserver,
    BTextInputValidation,
    MultiselectInput,
  },
})
export default class UpdateProductModal extends Vue {
  tryingToUpdate = false;
  needUpdate = false;
  displayName = '';

  @Prop({
    required: true
  })
  updateCallback!: (response?: any) => any;

  @Prop({
    required: false,
    default: () => 0
  })
  id!: number;

  @Prop({
    required: false,
    default: () => ''
  })
  name!: string;

  @Prop({
    required: false
  })
  product?: ProductType;

  @Action('productUpdate', { namespace: 'products' })
  productUpdate!: (data: ProductCard<ProductsUpdateRequest>) => Promise<void>;

  created() {
    this.displayName = this.product?.name || this.name;
  }

  cancel() {
    this.$emit('close');

    if (this.needUpdate) {
      this.updateCallback();
    }
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      this.productUpdate({
        id: this.product?.id || this.id,
        params: {
          displayName: this.displayName
        }
      })
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          showToast(this.$t('products.successUpdate') as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          showToast(this.$t('products.errorUpdate') as any);
        });
    }
  }
}

import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import Box from '@/components/ui/box/box.vue';
import { ReportsProductBrandRequest } from '@/types/request/reports-request.types';
import { ProductBrandType } from '@/types/api/reports/product-brand.type';
import { DateTime } from 'luxon';
import {
  DATE_MODE_MONTHLY, DATE_MODE_CUSTOM_WEEKLY,
  DATEPICKER_FORMAT,
  getDateTitle, DATE_FORMAT, DATE_MODE_DAILY,
} from '@/helpers/date.helper';
import DatepickerRangeMode from '@/components/ui/inputs/datepicker-range-mode/datepicker-range-mode.vue';
import ProductBrandTable from '@/components/pages/reports/product-brand-report/product-brand-table/product-brand-table.vue';
import { BFormRadioGroup } from 'bootstrap-vue';
import ExportXlsxBtn from '@/components/ui/buttons/export-xlsx-btn/export-xlsx-btn.vue';
import { exportToXls, getXlsFilename } from '@/helpers/xlsx.helper';
import { numberFormatter } from '@/helpers/string.helper';
import FocusMixin from '@/mixins/focus.mixin';
import ModeSwitcher from '@/components/ui/mode-switcher/mode-switcher.vue';
import ProductDropdownInput from '@/components/ui/inputs/product-dropdown-input/product-dropdown-input.vue';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import { BrandType } from '@/types/api/brands/brand.type';
import ProductBox from '@/components/ui/product-box/product-box.vue';
import ChartsBox from '@/components/ui/charts-box/charts-box.vue';

const title = 'brandProducts.title';

@Component({
  name: 'ProductBrandReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    GridTable,
    BoxHeader,
    Box,
    DatepickerRangeMode,
    ProductBrandTable,
    BFormRadioGroup,
    ExportXlsxBtn,
    ModeSwitcher,
    ProductDropdownInput,
    ProductBox,
    ChartsBox,
  },
})
export default class ProductBrandReport extends Mixins(FilterMixin, FocusMixin) {
  title = title;
  saveFilter = true;
  selectedBrand: BrandType | null = null;
  useBrandFilter = true;

  @Action('getProductBrandReport', { namespace: 'reports' })
  getProductBrandReport!: (params?: ReportsProductBrandRequest) => Promise<ProductBrandType>;

  @Getter('getProductBrandReport', { namespace: 'reports' })
  productBrandReport!: ProductBrandType;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Action('getBrands', { namespace: 'brands' })
  getBrands!: () => Promise<BrandType[]>;

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.filter = this.initFilter()
    this.getProductBrandReportRequest();

    if (this.pageFilter.brandId) {
      this.getProductsRequest(this.pageFilter.brandId);
    }
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value })
  }

  get startMonthDate() {
    return DateTime.local().setZone('utc').startOf('month').toFormat(DATEPICKER_FORMAT);
  }

  get endMonthDate() {
    return DateTime.local().endOf('month').toFormat(DATEPICKER_FORMAT);
  }

  getProductBrandReportRequest() {
    return this.getProductBrandReport(this.preparedFilter);
  }

  initFilter() {
    return {
      from: this.$route.query.from || this.startMonthDate,
      to: this.$route.query.to || this.endMonthDate,
      type: this.$route.query.type || DATE_MODE_MONTHLY,
      productName: this.$route.query.productName || null,
      asin: this.$route.query.asin || null,
      keywords: this.$route.query.keywords || null,
      productId: this.$route.query.productId || null,
    };
  }

  get preparedFilter() {
    return {
      ...this.filter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
    }
  }

  get modeOptions () {
    return [
      { text: this.$t('dateMode.daily'), value: DATE_MODE_DAILY },
      { text: this.$t('dateMode.monthly'), value: DATE_MODE_MONTHLY },
      { text: this.$t('dateMode.weekly'), value: DATE_MODE_CUSTOM_WEEKLY },
    ]
  }

  onRefresh() {
    this.getProductBrandReportRequest();
  }

  get productBrands() {
    return this.productBrandReport?.brands || [];
  }

  onExport() {
    if (this.productBrands.length) {
      const data: any = [];
      const fileName = getXlsFilename(this.$t(title) as string, this.filter);
      this.productBrands.forEach(brandItm => {
        brandItm.products.forEach(productItm => {
          Object.values(productItm.statistics).forEach(statValues => {
            data.push({
              [this.$t('common.period') as string]: getDateTitle(statValues.from, statValues.to, this.filter.type),
              [this.$t('brandProducts.brand') as string]: brandItm.brandName,
              [this.$t('brandProducts.product') as string]: productItm.productName,
              [this.$t('brandProducts.asin') as string]: productItm.asin,
              [this.$t('brandProducts.impressions') as string]: numberFormatter(statValues.impressions),
              [this.$t('brandProducts.clicks') as string]: numberFormatter(statValues.clicks),
              [this.$t('brandProducts.ppcOrders') as string]: numberFormatter(statValues.ppcOrders),
              [this.$t('brandProducts.ppcAdSpend') as string]: `$${numberFormatter(statValues.spend)}`,
              [this.$t('brandProducts.totalUnits') as string]: numberFormatter(statValues.totalUnits),
              [this.$t('brandProducts.unitOrganic') as string]: numberFormatter(statValues.totalUnits > statValues.ppcUnits ? statValues.totalUnits - statValues.ppcUnits : 0),
              [this.$t('brandProducts.unitPPC') as string]: numberFormatter(statValues.ppcUnits),
              [this.$t('brandProducts.totalSales') as string]: numberFormatter(statValues.totalSales),
              [this.$t('brandProducts.organicSales') as string]: `$${numberFormatter(statValues.totalSales > statValues.ppcSales ? statValues.totalSales - statValues.ppcSales : 0)}`,
              [this.$t('brandProducts.ppcSales') as string]: `$${numberFormatter(statValues.ppcSales)}`,
              [this.$t('brandProducts.tacos') as string]: `${statValues.totalSales ? numberFormatter((statValues.spend / statValues.totalSales) * 100, { fractionPartCount: 0 }) : 0}%`,
              [this.$t('brandProducts.acos') as string]: `${statValues.ppcSales ? numberFormatter((statValues.spend / statValues.ppcSales) * 100, { fractionPartCount: 0 }) : 0}%`,
              [this.$t('brandProducts.ppcConversation') as string]: `${statValues.clicks ? numberFormatter((statValues.ppcOrders / statValues.clicks) * 100, { fractionPartCount: 0 }) : 0}%`,
            })
          })
        });
      });
      exportToXls(fileName, data);
    }
  }

  setBrandById(id: number) {
    const brand = this.brands.find(item => item.id === Number(id));
    if (brand) {
      this.selectedBrand = brand;
    }
  }

  get selectedProduct() {
    return this.products.find(item => item.id === Number(this.filter.productId))
  }
}

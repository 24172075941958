import ProductBrandTableStatistics from '@/components/pages/reports/product-brand-report/product-brand-table/product-brand-table-statistics.vue';
import ProductBrandTableBrand from '@/components/pages/reports/product-brand-report/product-brand-table/product-brand-table-brand.vue';
import NotFoundIcon from '@/components/ui/icons/not-found-icon/not-found-icon.vue';
import { getDateTitle } from '@/helpers/date.helper';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductBrandType, ProductType } from '@/types/api/reports/product-brand.type';
import UpdateProductModal from '@/components/features/modals/update-product-modal/update-product-modal.vue';
import { MODAL_CONFIG } from '@/helpers/app.helper';

@Component({
  name: 'ProductBrandTable',
  components: {
    ProductBrandTableStatistics,
    ProductBrandTableBrand,
    NotFoundIcon,
  },
})
export default class ProductBrandTable extends Vue {
  fullSize = false;

  @Prop({ required: true })
  productBrandReport!: ProductBrandType;

  @Prop({ required: true })
  mode!: number;

  @Prop({
    required: false,
    default: () => false
  })
  oneProduct?: boolean

  get statistics() {
    const statistics = this.productBrandReport?.brands[0]?.products[0]?.statistics;
    return statistics ? Object.values(statistics) : [];
  }

  get reportMonths() {
    const statistics = this.productBrandReport?.brands[0]?.products[0]?.statistics;
    return statistics ? Object.keys(statistics) : [];
  }

  get productBrandReportData() {
    return this.productBrandReport?.brands
      ? this.productBrandReport.brands.map(item => ({ ...item, _showDetails: true }))
      : [];
  }

  getDateTitle({ from, to }) {
    return getDateTitle(from, to, this.mode);
  }

  onClickProductName(product: ProductType) {
    this.$modal.show(UpdateProductModal, {
      id: product.productId,
      name: product.productName,
      updateCallback: () => {
        this.$emit('refresh');
      }
    }, MODAL_CONFIG);
  }

  onBrandToggle(brandName: string, products: ProductType[], isOpen: boolean) {
    const tableRef = this.$refs['product-brand-table']
    const rows = (tableRef as HTMLTableElement).getElementsByClassName(this.prepareClass(`brand-${brandName}`))

    Array.from(rows).forEach(item => {
      if (isOpen) {
        item?.classList.remove('hidden');
      } else {
        item?.classList.add('hidden');
      }
    })
  }

  prepareClass(value: string) {
    return value.replace(' ', '_')
  }

  moveToColumn(event) {
    event.target.scrollIntoView({
      block: 'end',
      behavior: 'smooth'
    });
  }

  changeFullSize() {
    this.fullSize = !this.fullSize;
  }
}

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';
import { ProductType } from '@/types/api/products/product.type';
import { BImg, VBTooltip } from 'bootstrap-vue';

@Component({
  name: 'ProductDropdownInput',
  components: {
    BTextInputValidation,
    BImg
  },
  directives: {
    'b-tooltip': VBTooltip
  },
})
export default class ProductDropdownInput extends Vue {
  innerValue: string = '';
  items: ProductType[] = [];
  isShowList = false;
  selectedItem?: ProductType;

  @Prop({
    type: Array,
    required: true
  })
  options!: ProductType[];

  @Prop({
    default: () => null
  })
  value?: string;

  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  @Watch('value')
  valueChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.innerValue = '';
    }
  }

  @Watch('innerValue')
  innerValueChange(newVal) {
    const value = this.selectedItem?.id || '';
    this.$emit('input', newVal ? value : newVal);
    if (!newVal) {
      this.selectedItem = undefined;
      this.$emit('choose', newVal);
    }
  }

  @Watch('options')
  optionsChange(newVal: any) {
    this.items = newVal;
    this.setSelectedItem(newVal);
  }

  created() {
    if (this.value && this.options.length) {
      this.setSelectedItem(this.options);
    }
    this.items = this.options || [];
    this.hideList = debounce(this.hideList, 170);
  }

  setSelectedItem(options: ProductType[]) {
    if (this.value) {
      this.selectedItem = options.find(item => item.id?.toString() === this.value!.toString());
      this.innerValue = this.getFullName(this.selectedItem!);
    } else {
      this.innerValue = this.emptyProduct.displayName;
      this.selectedItem = this.emptyProduct;
    }
  }

  onKeyUp() {
    if (this.innerValue) {
      this.items = this.options.filter(
        itm => this.getName(itm).toLowerCase().includes(this.innerValue.toLowerCase())
          || itm.asin.toLowerCase().includes(this.innerValue.toLowerCase())
      );
    } else {
      this.items = this.options;
    }
  }

  hideList() {
    this.isShowList = false;
  }

  onFocus() {
    if (this.items.length) {
      this.isShowList = true;
    }
  }

  onFocusOut() {
    this.hideList();
  }

  choose(item: ProductType) {
    this.isShowList = false;
    this.selectedItem = item;
    this.innerValue = item.id !== undefined ? this.getFullName(item) : '';
    this.$emit('input', item.id);
    this.$emit('choose', item.id);
  }

  getItemClass(item: ProductType) {
    return { active: item.id === this.selectedItem?.id }
  }

  get emptyProduct(): ProductType {
    return {
      id: null,
      displayName: '---'
    } as unknown as ProductType
  }

  getNamePreview(value: string) {
    return value?.slice(0, 80);
  }

  getFullName(item: ProductType) {
    return `${item.asin || ''} - ${item.displayName || item.name || ''}`
  }

  getName(item: ProductType) {
    return `${item.displayName || item.name || ''}`
  }
}

<template>
  <tr class="product-brand-table-brand">
    <td
      v-if="firstProduct"
      class="product-table__cell cursor-pointer nowrap td-break _first-cell"
      @click="toggle"
      :rowspan="3"
    >
      <div class="product-block">
        <div class="product-table__info">
          <span class="font-weight-bold cursor-pointer" >
            {{ productBrand.brandName }}
            <i :class="`${isOpen ? 'fe-chevron-up' : 'fe-chevron-down'}`" />
          </span>
        </div>
        <div class="product-names">
          <div>{{ $t('brandProducts.totalSales') }}</div>
          <div>{{ $t('brandProducts.organicSales') }}</div>
          <div>{{ $t('brandProducts.ppcSales') }}</div>
        </div>
      </div>
    </td>
    <template v-for="month of months">
      <td :key="`${month}-impressions`" class="product-table__cell">
        <div class="td-row">{{ getImpressions(month) }}</div>
      </td>
      <td :key="`${month}-clicks`" class="product-table__cell">
        <div class="td-row">
          <div class="td-row-left">{{ getClicks(month) }}</div>
          <div
            v-if="getClicks(month)"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`CTR - ${getCtr(month)}%`"
          >
            {{ getCtr(month) }}%
          </div>
        </div>
      </td>
      <td :key="`${month}-ppcOrders`" class="product-table__cell td-break">
        <div class="td-row">
          <div class="td-row-left">{{ getPpcOrders(month) }}</div>
          <div
            v-if="getPpcOrders(month)"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`${$t('productViewReport.conversation')} - ${getConversation(month)}%`"
          >
            {{ getConversation(month) }}%
          </div>
        </div>
      </td>
      <td :key="`${month}-unitPPC`" class="product-table__cell">
        <div class="td-row">{{ getPpcUnits(month) }}</div>
      </td>
      <td :key="`${month}-ppcSales`" class="product-table__cell">
        <div class="td-row">${{ getPpcSales(month) }}</div>
      </td>
      <td :key="`${month}-ppcCost`" class="product-table__cell td-break">
        <div class="td-row">
          <div class="td-row-left">
            <span v-if="getSpend(month)">${{ getSpend(month) }}</span>
            {{ type === 'totalSales' ? $t('productViewReport.tacos') : '' }}</div>
          <div
            v-if="type !== 'organicSales'"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`${$t('productViewReport.acos')} - ${getAcos(month)}%`"
          >
            {{ type === 'totalSales' ? getTacos(month) : getAcos(month) }}%
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>

<script type="tsx">
import { numberFormatter } from '@/helpers/string.helper';
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'ProductBrandTableBrand',
  props: {
    productBrand: {
      required: true,
    },
    months: {
      required: true,
    },
    type: {
      required: true,
    },
    firstProduct: {
      required: false,
      default: false
    },
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      stats: {},
      isOpen: true
    }
  },
  created() {
    this.productBrand.products.forEach(item => Object.entries(item.statistics).forEach(([month, stats]) => {
      if (!this.stats[month]) {
        this.stats[month] = {};
      }

      Object.entries(stats).forEach(([attrName, attrValue]) => {
        this.stats[month][attrName] = this.stats[month][attrName] !== undefined
          ? this.stats[month][attrName] + attrValue
          : 0;
      })
    }));
  },
  methods: {
    getImpressions(month) {
      const value = this.getRawValueByMonth(month, 'impressions')
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 0 })
        : ''
    },
    getClicks(month) {
      const value = this.getRawValueByMonth(month, 'clicks')
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 0 })
        : ''
    },
    getPpcOrders(month) {
      const value = this.getRawValueByMonth(month, 'ppcOrders')
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 0 })
        : ''
    },
    getPpcUnits(month) {
      let value = 0
      const totalUnits = this.getRawValueByMonth(month, 'totalUnits')
      const ppcUnits = this.getRawValueByMonth(month, 'ppcUnits')

      switch (this.type) {
        case 'totalSales': value = totalUnits; break;
        case 'organicSales':
          value = totalUnits > ppcUnits ? totalUnits - ppcUnits : 0;
          break;
        case 'ppcSales': value = ppcUnits; break;
      }

      return numberFormatter(value, { fractionPartCount: 0 })
    },
    getPpcSales(month) {
      let value = 0
      const totalSales = this.getRawValueByMonth(month, 'totalSales')
      const ppcSales = this.getRawValueByMonth(month, 'ppcSales')

      switch (this.type) {
        case 'totalSales': value = totalSales; break;
        case 'organicSales':
          value = totalSales > ppcSales ? totalSales - ppcSales : 0
          break;
        case 'ppcSales': value = ppcSales; break;
      }

      return numberFormatter(value, { fractionPartCount: 1 })
    },
    getCtr(month) {
      const clicks = this.getRawValueByMonth(month, 'clicks')
      const impressions = this.getRawValueByMonth(month, 'impressions')
      return numberFormatter(clicks / impressions, { fractionPartCount: 1 })
    },
    getConversation(month) {
      const value = this.getRawValueByMonth(month, 'conversion')
      return numberFormatter(value, { fractionPartCount: 1 })
    },
    getSpend(month) {
      const value = this.getRawValueByMonth(month, 'spend')
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 1 })
        : ''
    },
    getAcos(month) {
      const spend = this.getRawValueByMonth(month, 'spend')
      const ppcSales = this.getRawValueByMonth(month, 'ppcSales')
      return numberFormatter(ppcSales? (spend / ppcSales) * 100 : 0, { fractionPartCount: 0 })
    },
    getTacos(month) {
      const spend = this.getRawValueByMonth(month, 'spend')
      const totalSales = this.getRawValueByMonth(month, 'totalSales')
      return numberFormatter(totalSales? (spend / totalSales) * 100 : 0, { fractionPartCount: 0 })
    },
    getRawValueByMonth(month, attr) {
      let result = 0;
      switch (attr) {
        case 'unitOrganic':
          result = this.stats[month]?.totalUnits || 0 - this.stats[month]?.ppcUnits || 0;
          break;
        case 'organicSales':
          result = this.stats[month]?.totalSales || 0 - this.stats[month]?.ppcSales || 0;
          break;
        case 'acos':
          result = this.stats[month]?.ppcSales ? (this.stats[month].spend / this.stats[month].ppcSales) * 100 : 0;
          break;
        case 'tacos':
          result = this.stats[month]?.totalSales ? (this.stats[month].spend / this.stats[month].totalSales) * 100 : 0;
          break;
        case 'ppcConversation':
          result = this.stats[month]?.clicks ? (this.stats[month].ppcOrders / this.stats[month].clicks) * 100 : 0;
          break;
        default: result = this.stats[month]?.[attr];
      }

      return result
    },
    getTotalByMonth(month, attr) {
      let result = this.getRawValueByMonth(month, attr);
      return numberFormatter(result || 0, { fractionPartCount: 0 });
    },
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen);
    },
  }
};
</script>
<style scoped lang="scss"></style>

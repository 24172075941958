import { render, staticRenderFns } from "./product-brand-table-brand.vue?vue&type=template&id=6388ddd5&scoped=true&"
import script from "./product-brand-table-brand.vue?vue&type=script&lang=js&"
export * from "./product-brand-table-brand.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6388ddd5",
  null
  
)

export default component.exports
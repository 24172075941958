<template>
  <tr class="product-table__tr product-brand-table-statistics">
    <td
      v-if="firstProduct"
      class="product-table__cell _first-cell td-break"
      :rowspan="3"
    >
      <div class="product-block">
        <ProductBox
          v-if="!oneProduct"
          :product="product"
          name-attr="productName"
          img-width="90px"
          img-height="50px"
          @click="onClickProductName"
          class="w-100"
        />
        <div class="product-names">
          <div>{{ $t('brandProducts.totalSales') }}</div>
          <div>{{ $t('brandProducts.organicSales') }}</div>
          <div>{{ $t('brandProducts.ppcSales') }}</div>
        </div>
      </div>
    </td>
    <template v-for="statistics of Object.entries(product.statistics)">
      <td :key="`${statistics[0]}-impressions`" class="product-table__cell">
        <div class="td-row">{{ getImpressions(statistics) }}</div>
<!--        <div v-if="getImpressions(statistics)" class="td-text">-->
<!--          {{ $t('brandProducts.impressions') }}-->
<!--        </div>-->
      </td>
      <td :key="`${statistics[0]}-clicks`" class="product-table__cell">
        <div class="td-row">
          <div class="td-row-left">{{ getClicks(statistics) }}</div>
          <div
            v-if="getClicks(statistics)"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`CTR - ${getCtr(statistics)}%`"
          >
            {{ getCtr(statistics) }}%
          </div>
        </div>
<!--        <div v-if="getClicks(statistics)" class="td-text">-->
<!--          {{ $t('brandProducts.clicks') }}-->
<!--        </div>-->
      </td>
      <td :key="`${statistics[0]}-ppcOrders`" class="product-table__cell td-break">
        <div class="td-row">
          <div class="td-row-left">{{ getPpcOrders(statistics) }}</div>
          <div
            v-if="getPpcOrders(statistics)"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`${$t('productViewReport.conversation')} - ${getConversation(statistics)}%`"
          >
            {{ getConversation(statistics) }}%
          </div>
        </div>
<!--        <div v-if="getPpcOrders(statistics)" class="td-text">-->
<!--          {{ $t('brandProducts.ppcOrders') }}-->
<!--        </div>-->
      </td>
      <td :key="`${statistics[0]}-unitPPC`" class="product-table__cell">
        <div class="td-row">{{ getPpcUnits(statistics) }}</div>
<!--        <div v-if="getPpcUnits(statistics)" class="td-text">-->
<!--          {{ $t('brandProducts.unitPPC') }}-->
<!--        </div>-->
      </td>
      <td :key="`${statistics[0]}-ppcSales`" class="product-table__cell">
        <div class="td-row">${{ getPpcSales(statistics) }}</div>
<!--        <div v-if="getPpcSales(statistics)" class="td-text">-->
<!--          {{ $t('brandProducts.ppcSales') }}-->
<!--        </div>-->
      </td>
      <td :key="`${statistics[0]}-ppcCost`" class="product-table__cell td-break">
        <div class="td-row">
          <div class="td-row-left">
            <span v-if="getSpend(statistics)">${{ getSpend(statistics) }}</span>
            {{ type === 'totalSales' ? $t('productViewReport.tacos') : '' }}
          </div>
          <div
            v-if="type !== 'organicSales'"
            class="td-row-right"
            v-b-tooltip.hover
            :title="`${$t('productViewReport.acos')} - ${getAcos(statistics)}%`"
          >
            {{ type === 'totalSales' ? getTacos(statistics) : getAcos(statistics) }}%
          </div>
        </div>
<!--        <div v-if="getSpend(statistics)" class="td-text">-->
<!--          {{ $t('brandProducts.ppcCost') }}-->
<!--        </div>-->
      </td>
    </template>
  </tr>
</template>

<script type="tsx">
import { numberFormatter } from '@/helpers/string.helper';
import ProductBox from '@/components/ui/product-box/product-box.vue';
import { VBTooltip } from 'bootstrap-vue';

export default {
  name: 'ProductBrandTableStatistics',
  components: {
    ProductBox,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    product: {
      required: true,
    },
    firstProduct: {
      required: false,
      default: false
    },
    oneProduct: {
      required: false,
      default: false
    },
    type: {
      required: true,
    },
  },
  methods: {
    onClickProductName() {
      this.$emit('product-click', this.product);
    },
    getImpressions(statistics) {
      const value = statistics[1].impressions
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 0 })
        : ''
    },
    getClicks(statistics) {
      const value = statistics[1].clicks
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 0 })
        : ''
    },
    getPpcOrders(statistics) {
      const value = statistics[1].ppcOrders
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 0 })
        : ''
    },
    getCtr(statistics) {
      const clicks = statistics[1].clicks
      const impressions = statistics[1].impressions
      return numberFormatter(impressions ? clicks / impressions : 0, { fractionPartCount: 1 })
    },
    getConversation(statistics) {
      const ppcOrders = statistics[1].ppcOrders
      const clicks = statistics[1].clicks
      return numberFormatter(clicks? (ppcOrders / clicks) * 100 : 0, { fractionPartCount: 0 })
    },
    getPpcUnits(statistics) {
      let value = 0

      switch (this.type) {
        case 'totalSales': value = statistics[1].totalUnits; break;
        case 'organicSales':
          value = statistics[1].totalUnits > statistics[1].ppcUnits ? statistics[1].totalUnits - statistics[1].ppcUnits : 0;
          break;
        case 'ppcSales': value = statistics[1].ppcUnits; break;
      }

      return numberFormatter(value, { fractionPartCount: 0 })
    },
    getPpcSales(statistics) {
      let value = 0

      switch (this.type) {
        case 'totalSales': value = statistics[1].totalSales; break;
        case 'organicSales':
          value = statistics[1].totalSales > statistics[1].ppcSales ? statistics[1].totalSales - statistics[1].ppcSales : 0
          break;
        case 'ppcSales': value = statistics[1].ppcSales; break;
      }

      return numberFormatter(value, { fractionPartCount: 1 })
    },
    getSpend(statistics) {
      const value = statistics[1].spend
      return this.type === 'ppcSales'
        ? numberFormatter(value, { fractionPartCount: 1 })
        : ''
    },
    getAcos(statistics) {
      const spend = statistics[1].spend
      const ppcSales = statistics[1].ppcSales
      return numberFormatter(ppcSales? (spend / ppcSales) * 100 : 0, { fractionPartCount: 0 })
    },
    getTacos(statistics) {
      const spend = statistics[1].spend
      const totalSales = statistics[1].totalSales
      return numberFormatter(totalSales? (spend / totalSales) * 100 : 0, { fractionPartCount: 0 })
    },
  }
};
</script>
<style scoped lang="scss">
/deep/ .card-box {
  box-shadow: none !important;
  border-radius: 0;
  height: 100%;
}
</style>

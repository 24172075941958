import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    Multiselect,
    ValidationProvider
  }
})
export default class MultiselectInput extends Vue {
  @Prop()
  label?: string;

  @Prop()
  labelFor?: string;

  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  @Prop()
  value?: string;

  innerValue: any = null;

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  mounted() {
    const multiselect: any = this.$refs.multiselect;
    if (multiselect) {
      multiselect.$refs.list.classList.add('shadow-sm');
    }
  }
}

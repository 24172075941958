import { Component, Vue } from 'vue-property-decorator';
import { CreateElement, RenderContext, VNode } from 'vue';

@Component({
  name: 'ChartsBox',
  components: {
    VNodes: {
      functional: true,
      render(createElement: CreateElement, context: RenderContext<any>): VNode | VNode[] {
        return context.props.item;
      }
    }
  }
})
export default class ChartsBox extends Vue {}
